import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./fonts/GT-Alpina-Standard-Regular.ttf";
import "./index.css";

ReactDOM.render(
    <Suspense fallback="Loading...">
        <App />
    </Suspense>,
    document.getElementById("root")
);
