import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Slider from "react-slick";
import useMedia from "use-media";


import getEventPosition from "../utilities/getEventPosition";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Home.css";
import "../styles/Projects.css";

export default ({ projects }) => {
    const [infoModal, setInfoModal] = useState(false);
    const slider = useRef(null);
    const isMobile = useMedia({ maxWidth: "480px" });

    // event handler for closing the info modal
    const handleCloseInfoModal = useCallback(event => {
        if (
            event.keyCode === 27 ||
            (event.type === "click" && event.target.tagName !== "BUTTON")
        ) {
            setInfoModal(false);
        }
    }, []);

    useEffect(() => {
        // register keydown event listener for closing the info modal
        window.addEventListener("keydown", handleCloseInfoModal);
        return () => {
            window.removeEventListener("keydown", handleCloseInfoModal);
        };
    }, []);

    // get the URL pathname, hash, parameters & the router's history object
    const { id } = useParams();
    let { hash, pathname } = useLocation();
    const history = useHistory();

    // find the current project based on the URL's ID parameter
    const {
        files,
        content: { projectinfo },
    } = projects.find(project => project.id === id);

    // change URL hash on new slider content
    useEffect(() => {
        // close info modal when slider changes (except on mobile)
        setInfoModal(false);

        if (files && !hash) {
            history.replace({
                pathname: pathname,
                hash: `#${files[0].name}`,
            });
        }

        // eslint-disable-next-line no-unused-expressions
        document.querySelector(".slick-active")?.focus();
    }, [pathname, hash, files]);

    // set initial slide to the one specified in URL hash
    let initialSlide = 0;
    if (hash && files) {
        hash = hash.substring(1);
        initialSlide =
            files.findIndex(file => file.name === hash) || initialSlide;
    }

    // navigate to next or previous slide depending on location of click event
    const handleMediaClicked = e => {
        // don't handle media click if file is video
        // if (files[initialSlide].type === "video") {
        //     return;
        // }
        const position = getEventPosition(e);
        position === "right"
            ? slider.current.slickNext()
            : slider.current.slickPrev();
    };

    // change cursor styling to left/right arrows depending on current position
    const onMouseMove = e => {
        // don't change cursor style if file is video
        // if (files[initialSlide].type === "video") {
        //     return;
        // }
        const position = getEventPosition(e);
        position === "right"
            ? (e.currentTarget.className = "rightCursor")
            : (e.currentTarget.className = "leftCursor");
    };

    const showInfoFooter = pageNumber => {
        return (
            <section className="info">
                <button
                    onClick={() => setInfoModal(!infoModal)}
                    className={infoModal ? "active" : ""}
                >
                    Info
                </button>
                <p className="pages">{`${pageNumber}/${files.length}`}</p>
            </section>
        );
    };

    const sliderConfig = {
        initialSlide: initialSlide,
        dots: false,
        arrows: false,
        autoplay: false,
        speed: 0,
        infinite: true,
        adaptiveHeight: true,
        beforeChange: (prev, next) => {
            if (files && files[prev].type === "video") {
                document.querySelector("video").pause();
            }
        },
        afterChange: index =>
            files &&
            history.replace({
                pathname: pathname,
                hash: `#${files[index].name}`,
            }),
    };

    return (
        <div className="main-container" onClick={handleCloseInfoModal}>
            {isMobile || !infoModal ? (
                <Slider {...sliderConfig} ref={slider} key={pathname}>
                    {files?.map((file, id) => (
                        <div className="slide-container" key={file.name}>
                            <div
                                onClick={handleMediaClicked}
                                onMouseMove={onMouseMove}
                                style={{display: "flex"}}
                            >
                                {file.type === "image" ? (
                                    <img
                                        className="media"
                                        src={file.url}
                                        alt={file.content.alt}
                                    />
                                ) : (
                                    <video
                                        className="media"
                                        controls
                                        muted={true}
                                        loop={file.content?.video?.includes("loop") ? true : null}
                                        autoPlay={file.content?.video?.includes("autoplay") ? true : null }
                                    >
                                        <source
                                            src={file.url}
                                            type={file.mime}
                                        />
                                    </video>
                                )}
                            </div>
                            <div className="image-info">
                                <p className="image-title">
                                    {file.content.imageinfo}
                                </p>
                            </div>
                            {showInfoFooter(id + 1)}
                        </div>
                    ))}
                </Slider>
            ) : (
                <>
                    <div
                        className="projectInfo"
                        dangerouslySetInnerHTML={{ __html: projectinfo }}
                    />
                    {showInfoFooter(files.findIndex(file => file.name === hash) + 1)}
                </>
            )}
            {isMobile && infoModal && (
                <div
                    className="projectInfo"
                    dangerouslySetInnerHTML={{
                        __html: projectinfo,
                    }}
                />
            )}
        </div>
    );
};
