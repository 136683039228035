import React from "react";
import useFetch from "fetch-suspense";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Analytics from "react-router-ga";

import Home from "./Home";
import SideMenu from "./SideMenu";
import About from "./About";
import Projects from "./Projects";

import "../styles/App.css";

export default () => {
    const auth = btoa("api@kirby.com:WLeBc58Dvz3ge!7");
    let site = useFetch("/rest/site?select=content,files", {
        headers: { Authorization: `Basic ${auth}` },
    });
    let pages = useFetch("/rest/site/children?select=id,content,files", {
        headers: { Authorization: `Basic ${auth}` },
    });

    const { content, files } = site.data;

    // get logo
    const logo = files?.find(file => file.template === "logo")?.url || "";

    // set favicon
    document.querySelector("link[rel*='icon']").href =
        files?.find(file => file.template === "favicon")?.url || "";

    const about = pages.data.find(project => project.id === "about");
    const home = pages.data.find(project => project.id === "home");
    const projects = pages.data.filter(
        project => !["about", "home", "error"].includes(project.id)
    );

    return (
        <div className="App">
            <Router>
                <Analytics id={content.googleanalytics}>
                    <SideMenu projects={projects} home={home} logo={logo} />
                    <Switch>
                        <Route exact path="/">
                            <Home content={home} />
                        </Route>
                        <Route path="/about">
                            <About content={about} />
                        </Route>
                        <Route path="/projects/:id">
                            <Projects projects={projects} />
                        </Route>
                    </Switch>
                </Analytics>
            </Router>
        </div>
    );
};
