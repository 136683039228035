// calculates if the event position is on the left or right side of its target
export default e => {
    const currentTargetRect = e.currentTarget.getBoundingClientRect();
    const x = e.pageX - currentTargetRect.left;
    const width = currentTargetRect.width;
    if (width / 2 < x) {
        return "right";
    } else {
        return "left";
    }
};
