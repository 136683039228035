import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../styles/SideMenu.css";
import { Link } from "react-router-dom";

export default ({ projects, home, logo }) => {
    const location = useLocation();
    const projectsView = location.pathname.startsWith("/projects/");
    const aboutView = location.pathname.startsWith("/about");

    return (
        <>
            <div
                className={`sidemenu-container ${
                    projectsView ? "projects" : aboutView ? "aboutView" : ""
                }`}
            >
                <Link to="/">
                    <img className="logo" src={logo} alt="logo" />
                </Link>
                <div className="sidemenu">
                    <section
                        className="infos il"
                        dangerouslySetInnerHTML={{ __html: home.content.info }}
                    />

                    <section className="menuabout">
                        <NavLink to="/about">About</NavLink>
                    </section>

                    <section className="commissions">
                        <NavLink to={`/projects/commissions`}>Commissions</NavLink>
                    </section>
                    <section className="projects">
                        <ul>
                            {projects.map((project, index) => (
                                project.id !== "commissions" ?
                                <li key={index}>
                                    <NavLink
                                        className="projectnames"
                                        to={`/projects/${project.id}`}
                                    >
                                        {project.content.title}
                                    </NavLink>
                                </li> : null
                            ))}
                        </ul>
                    </section>
                </div>
            </div>
        </>
    );
};
