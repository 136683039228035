import React from "react";
import "../styles/Home.css";

export default ({ content }) => {
    const image = content.files &&
        content.files[Math.floor(Math.random() * content.files.length)];

    return (
        <div className="main-container">
            <img className="media" src={image && image.url} alt="" />
            <div className="image-info">
                <p className="image-title">{image.content.title}</p>
            </div>
        </div>
    );
};
